<template>
  <a-modal
    title="充值模板预览"
    :width="370"
    :visible="visible"
    :footer="null"
    @cancel="handleCancel"
  >
    <TemplateView ref="RefTemplateView"></TemplateView>
  </a-modal>
</template>

<script setup>
import { ref } from 'vue'
import { message } from 'ant-design-vue'
import TemplateView from './TemplateView'
import { nextTick } from 'vue'

// 预览视图
let RefTemplateView = ref(null)
// 是否展示弹窗
let visible = ref(false)

// 展示弹窗
function showModal (id) {
  // 模版ID必须有值
  if (!id) {
    message.error('模版ID不能为空')
    return
  }
  // 显示
  visible.value = true
  // 延迟执行
  nextTick(() => {
    // 渲染预览
    RefTemplateView.value.load(id)
  })
}

// 关闭弹窗
function handleCancel () {
  visible.value = false
}

defineExpose({
  showModal
})

</script>

<style lang="less" scoped>
</style>