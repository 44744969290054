<template>
	<div class="product-div">
		<!-- 商品列表 -->
		<div class="product-list">
			<!-- 商品 -->
			<div
				v-for="(item, index) in data.list"
				:class="'product-item ' + item.type + ` ${item.is_highlight ? 'highlight' : '' }`"
				:key="item.id"
			>
				<!-- 虚拟币 -->
				<template v-if="item.type === 'coin'">
					<!-- 价格 -->
					<div class="coin-product-price">
						<span class="tag">￥</span>{{ moneyConversion(item.money) }}
					</div>
					<!-- 描述 -->
					<div class="coin-product-desc">
						<span>{{ item.coin }}{{ data.coin_name }}</span>
						<span v-if="item.free_coin > 0">+</span>
						<span v-if="item.free_coin > 0" class="reward">送{{ item.free_coin }}{{ data.coin_name }}</span>
					</div>
					<!-- 辅助语 -->
					<div class="coin-product-help" v-if="item.is_help_message">
						<span>{{ item.help_message }}</span>
					</div>
				</template>

				<!-- 全集解锁 -->
				<template v-if="item.type === 'unlock'">
					<!-- 背景 -->
					<!-- <div class="vip-bg">
						<img class="img" :src="imageURL + 'mix-vip-bg.svg'" />
					</div> -->
					<!-- 名称 -->
					<div class="vip-product-name">全集解锁</div>
					<!-- 价格 -->
					<div class="vip-product-price">
						<span class="tag">￥</span>{{ moneyConversion(item.money) }}
					</div>
					<!-- 描述 -->
					<div class="vip-product-desc">
						<div v-if="item.ispro">{{ item.desc1 }}<span class="discount">{{ item.desc2 }}</span>{{ item.desc3 }}</div>
						<div v-else>{{ item.description }}</div>
					</div>
				</template>
				
				<!-- 会员 -->
				<template v-if="item.type === 'vip'">
					<!-- 背景 -->
					<div class="vip-bg">
						<img class="img" :src="imageURL + 'mix-vip-bg.svg'" />
					</div>
					<!-- 名称 -->
					<div class="vip-product-name">{{ item.days }}天{{ data.vip_name }}</div>
					<!-- 价格 -->
					<div class="vip-product-price">
						<span class="tag">￥</span>{{ moneyConversion(item.money) }}
					</div>
					<!-- 描述 -->
					<div class="vip-product-desc">
						<div v-if="item.ispro">{{ item.desc1 }}<span class="discount">{{ item.desc2 }}</span>{{ item.desc3 }}</div>
						<div v-else>{{ item.description }}</div>
					</div>
				</template>
				
				<!-- 通用角标 -->
				<div class="product-tag" v-if="item.is_corner_on">
					<span>{{ item.corner_name }}</span>
				</div>
				<!-- 通用气泡 -->
				<div class="product-bubble" v-if="item.is_bubble_on">
					<span>{{ item.bubble_name }}</span>
					<!-- 三角形 -->
					<div class="triangle"></div>
				</div>
				<!-- 手势 -->
				<img
          v-if="item.is_gesture"
          class="product-touch"
          :src="imageURL + 'touch.gif'"
        />
				<!-- <com-icon
					v-if="item.is_gesture"
					class="product-touch"
					name="touch.gif"
					size="160"
				></com-icon> -->
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		// 数据
		data: {
			type: Object,
			default: () => { list: [] }
		},
	},
	data () {
		return {
			// 图片地址
			imageURL: this.$pub.appImageURL
		}
	},
	methods: {
		// 金额转换
		moneyConversion (money) {
			return this.$pub.KEEP_NUMBER_DECIMAL(money/100.0, 2, false, false)
		}
  }
}
</script>

<style lang="less" scoped>
.product-div {
	.product-list {
		// margin-top: 12px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.product-item {
      cursor: pointer;
			position: relative;
			width: calc(50% - 8px);
			height: 104px;
			background-color: #F7F7F7;
			border-radius: 4px;
			margin-bottom: 16px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			.product-touch {
				position: absolute;
        bottom: 20px;
        right: 14px;
        width: 80px;
        height: 80px;
				transform: translateX(50px) translateY(50px);
			}
			// 虚拟币
			.coin-product-price {
				margin-top: 3px;
				font-size: 28px;
				font-weight: 700;
				color: #3E3E40;
				.tag {
					margin-right: 6px;
					font-size: 15px;
				}
			}
			.coin-product-desc {
				// margin-top: 5px;
				font-size: 12px;
				color: #3E3E40;
        text-align: center;
				// span-align: center;
				.reward {
					color: #FF5300;
				}
			}
			.coin-product-help {
				color: rgba(0, 0, 0, 0.4);
				font-size: 12px;
				line-height: 22px;
			}
			// 会员
			&.vip {
				background: linear-gradient(270deg, rgba(255,226,148,0.81) 0%, #FFECC1 100%);
				align-items: flex-start;
				justify-content: flex-start;
				padding-left: 16px;
			}
			&.unlock {
				align-items: flex-start;
				justify-content: flex-start;
				padding-left: 16px;
			}
			&.highlight {
				background: none;
				background-color: #E66F1D;
				.coin-product-price {
					color: #FFF;
					.tag {
					}
				}
				.coin-product-desc {
					color: #FFF;
					.reward {
						color: #FFF;
					}
				}
				.coin-product-help {
					color: #FFF;
				}
				.vip-product-name {
					color: #FFF;
				}
				.vip-product-price {
					color: #FFF;
					.tag {
					}
				}
				.vip-product-desc {
					color: #FFF;
					.discount {
					}
				}
			}
			.vip-bg {
				position: absolute;
				width: 116px;
				height: 106px;
				right: 0;
				bottom: 0;
				overflow: hidden;
				.img {
					position: absolute;
					width: 100%;
					height: 100%;
					right: -10px;
					bottom: -10px;
				}
			}
			.vip-product-name {
				position: absolute;
				font-size: 14px;
				color: #3E3E40;
				line-height: 22px;
				top: 12px;
			}
			.vip-product-price {
				position: absolute;
				top: 40px;
				font-size: 22px;
				line-height: 22px;
				color: #3E3E40;
				font-weight: 700;
				.tag {
					font-size: 15px;
				}
			}
			.vip-product-desc {
				position: absolute;
				top: 68px;
				color: rgba(0, 0, 0, 0.4);
				font-size: 12px;
				line-height: 22px;
				.discount {
          text-decoration: line-through;
				}
			}
			// 通用角标
			.product-tag {
				position: absolute;
				right: 0;
				top: 0;
				padding: 0px 4px;
				font-size: 12px;
				border-radius: 4px;
				color: #3E3E40;
				background: linear-gradient(180deg, #FFEF9D 0%, #FFEF9D 0%, #F8DD52 100%, #F8DD52 100%);
			}
			// 通用气泡
			.product-bubble {
				font-size: 12px;
				position: absolute;
				height: 18px;
				line-height: 18px;
				padding: 0 10px;
				bottom: -8px;
				background: linear-gradient(283deg, #FFC300 0%, #FFF27C 37%, #F3BB22 75%, #FFFC48 100%);
				border-radius: 9px;
				color: #000000;
				// 动画
				animation: animationBubble 1s linear infinite;
				.triangle {
					position: absolute;
					top: -14px;
					left: calc(50% - 8px);
					border: 8px solid transparent;
					border-bottom: 8px #F8DD52 solid;
				}
			}
		}
	}
}
// 气泡动画
@keyframes animationBubble {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.05);
	}
	100% {
		transform: scale(1);
	}
}
</style>