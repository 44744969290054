<template>
  <div class="content">
    <div class="title">{{ detail.tmp_name }}</div>
    <PayMix :data="detail"></PayMix>
    <!-- vip -->
    <!-- v-if="drama.pay_config && drama.pay_config.vip && data.is_vip" -->
    <!-- :style="true ? 'background-image: linear-gradient(110deg, transparent -180%, rgba(255, 242, 124, 0.3), rgba(255, 242, 124, 0.3), transparent 180%);' : ''" -->
    <div
      v-if="detail.is_vip"
      class="popup-vip"
    >
      <img :src="imageURL + 'vip.svg'" style="width: 22px; height: 22px;">
      <span class="title">{{ detail.vip_name }}</span>
      <div class="desc">28.00元购买会员，所有嗨剧免费看</div>
      <img :src="imageURL + 'arrow-1.svg'" style="width: 22px; height: 20px;">
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue'
import { message } from 'ant-design-vue'
import { chargeAppDetail } from '@/api/operate'
import PayMix from './PayMix'
import Pub from '@/utils/public'

// 剧集信息
let detail = ref({})
// APP图片地址
let imageURL = ref(Pub.appImageURL)

// 加载弹窗
function load (id) {
  // 模版ID必须有值
  if (!id) {
    message.error('模版ID不能为空')
    return
  }
  // 清空
  detail.value = {}
  // 获取详情
  getDetail(id)
}

// 获取详情
function getDetail (id) {
  chargeAppDetail({ id: id }).then(res => {
    const { code, data } = res
    if (code === 0) {
      // 处理分割线数据
      data.list.forEach(item => {
        // 获取描述
        const desc = item.description
        // 标识符位置
        const start = desc.indexOf('[')
        const end = desc.indexOf(']')
        // 存在则处理
        if (start != -1 && end != -1) {
          // 获得 [￥999]
          var money = desc.substring(start, end + 1)
          // 获得 ￥999
          var num = money.replace('[', '').replace(']', '')
          // 分割字符串
          var strs = desc.split(money)
          // 记录
          item.ispro = true
          item.desc1 = strs[0] || ''
          item.desc2 = num
          item.desc3 = strs[1] || '' 
        }
      })
      // 赋值
      detail.value = data
    }
  })
}

defineExpose({
  load
})

</script>

<style lang="less" scoped>
.content {
  width: 320px;
  // 共用
  >.title {
    font-size: 16px;
    margin-bottom: 18px;
  }
	.popup-vip {
		// margin-bottom: 8px;
		display: flex;
		align-items: center;
		padding-left: 8px;
		height: 42px;
		border-radius: 4px;
		backdrop-filter: blur(60px);
		box-shadow: inset 0px 0px 20px 0px rgba(255, 249, 126, 0.5);
		white-space: nowrap;
		// 背景高亮色块及动画
		background: #ffde23 linear-gradient(110deg, transparent 20%, rgba(255, 242, 124, 0.8), rgba(255, 242, 124, 0.8), transparent 60%, transparent);
		background-size: 80px 80px;
		background-repeat: no-repeat;
		animation: animationVip 1.2s linear infinite;
		.title {
			padding: 0px 10px 0px 6px;
			font-size: 16px;
			font-weight: 700;
			color: #782313;
		}
		.desc {
			font-size: 12px;
			color: #664E1F;
		}
	}
}
// vip按钮动画
@keyframes animationVip {
	0% {
		background-position: -100% 0;
	}
	100% {
		background-position: 500% 0;
	}
}
</style>